import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

	const routes = [
	{
		path: '/',
		name: 'Dashboard',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
	},
	{
		path: '/profile/:user_id',
		name: 'Profile',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
	},
	{
		path: '/users_overview',
		name: 'UsersOverview',
		component: () => import(/* webpackChunkName: "users_overview" */ '../views/UsersOverview.vue')
	},
	{
		path: '/users',
		name: 'Users',
		component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
	},
	{
		path: '/user/:user_id',
		name: 'User',
		component: () => import(/* webpackChunkName: "user" */ '../views/User.vue')
	},
	{
		path: '/accounts',
		name: 'Accounts',
		component: () => import(/* webpackChunkName: "accounts" */ '../views/Accounts.vue')
	},
	{
		path: '/account/purge',
		name: 'Purge',
		component: () => import(/* webpackChunkName: "purge" */ '../views/Purge.vue')
	},
	{
		path: '/account/:account_id',
		name: 'Account',
		component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue')
	},
	{
		path: '/insurance_types',
		name: 'InsuranceTypes',
		component: () => import(/* webpackChunkName: "insurance_types" */ '../views/InsuranceTypes.vue')
	},
	{
		path: '/insurance_type/:insurance_type_id',
		name: 'InsuranceType',
		component: () => import(/* webpackChunkName: "insurance_type" */ '../views/InsuranceType.vue')
	},
	{
		path: '/insurers',
		name: 'Insurers',
		component: () => import(/* webpackChunkName: "insurers" */ '../views/Insurers.vue')
	},
	{
		path: '/insurer/:insurer_id',
		name: 'Insurer',
		component: () => import(/* webpackChunkName: "insurer" */ '../views/Insurer.vue')
	},
	{
		path: '/lead_sources',
		name: 'LeadSources',
		component: () => import(/* webpackChunkName: "lead_sources" */ '../views/LeadSources.vue')
	},
	{
		path: '/lead_source/:lead_source_id',
		name: 'LeadSource',
		component: () => import(/* webpackChunkName: "lead_source" */ '../views/LeadSource.vue')
	},
	{
		path: '/groups',
		name: 'Groups',
		component: () => import(/* webpackChunkName: "groups" */ '../views/Groups.vue')
	},
	{
		path: '/group/:group_id',
		name: 'Group',
		component: () => import(/* webpackChunkName: "group" */ '../views/Group.vue')
	},
	{
		path: '/phone_numbers',
		name: 'PhoneNumbers',
		component: () => import(/* webpackChunkName: "phone_numbers" */ '../views/PhoneNumbers.vue')
	},
	{
		path: '/phone_number/:account_phone_id',
		name: 'PhoneNumber',
		component: () => import(/* webpackChunkName: "phone_number" */ '../views/PhoneNumber.vue')
	},
	{
		path: '/queue',
		name: 'Queue',
		component: () => import(/* webpackChunkName: "queue" */ '../views/Queue.vue')
	},
	{
		path: '/csqueue',
		name: 'ContactStrategiesQueue',
		component: () => import(/* webpackChunkName: "csqueue" */ '../views/QueueDrip.vue')
	},
	{
		path: '/customers',
		name: 'Customers',
		component: () => import(/* webpackChunkName: "customers" */ '../views/Customers.vue')
	},
	{
		path: '/customer/:customer_id',
		name: 'Customer',
		component: () => import(/* webpackChunkName: "customer" */ '../views/Customer.vue')
	},
	{
		path: '/customer/:customer_id/:lead_id',
		name: 'Customer2',
		component: () => import(/* webpackChunkName: "customer" */ '../views/Customer.vue')
	},
	{
		path: '/leads',
		name: 'Leads',
		component: () => import(/* webpackChunkName: "leads" */ '../views/Leads.vue')
	},
	{
		path: '/actions',
		name: 'Actions',
		component: () => import(/* webpackChunkName: "actions" */ '../views/Actions.vue')
	},
	{
		path: '/action/:action_id',
		name: 'Action',
		component: () => import(/* webpackChunkName: "action" */ '../views/Action.vue')
	},
	{
		path: '/lead_statuses',
		name: 'LeadStatuses',
		component: () => import(/* webpackChunkName: "lead_statuses" */ '../views/LeadStatuses.vue')
	},
	{
		path: '/lead_status/:lead_status_id',
		name: 'LeadStatus',
		component: () => import(/* webpackChunkName: "lead_status" */ '../views/LeadStatus.vue')
	},
	{
		path: '/canned_responses',
		name: 'CannedResponses',
		component: () => import(/* webpackChunkName: "canned_responses" */ '../views/CannedResponses.vue')
	},
	{
		path: '/canned_response/:canned_response_id',
		name: 'CannedResponse',
		component: () => import(/* webpackChunkName: "canned_response" */ '../views/CannedResponse.vue')
	},
	{
		path: '/campaigns',
		name: 'DripCampaigns',
		component: () => import(/* webpackChunkName: "drip_campaigns" */ '../views/DripCampaigns.vue')
	},
	{
		path: '/campaign/:drip_campaign_id',
		name: 'DripCampaign',
		component: () => import(/* webpackChunkName: "drip_campaign" */ '../views/DripCampaign.vue')
	},
	{
		path: '/assignment_rules',
		name: 'AssignmentRules',
		component: () => import(/* webpackChunkName: "assignment_rules" */ '../views/AssignmentRules.vue')
	},
	{
		path: '/assignment_rule/:assignment_rule_id',
		name: 'AssignmentRule',
		component: () => import(/* webpackChunkName: "assignment_rule" */ '../views/AssignmentRule.vue')
	},
	{
		path: '/customer_merge_rules',
		name: 'MergeCustomerRules',
		component: () => import(/* webpackChunkName: "customer_merge_rules" */ '../views/MergeCustomerRules.vue')
	},
	{
		path: '/customer_merge_rule/:merge_rule_id',
		name: 'MergeCustomerRule',
		component: () => import(/* webpackChunkName: "customer_merge_rule" */ '../views/MergeCustomerRule.vue')
	},
	{
		path: '/lead_merge_rules',
		name: 'MergeLeadRules',
		component: () => import(/* webpackChunkName: "lead_merge_rules" */ '../views/MergeLeadRules.vue')
	},
	{
		path: '/lead_merge_rule/:merge_rule_id',
		name: 'MergeLeadRule',
		component: () => import(/* webpackChunkName: "lead_merge_rule" */ '../views/MergeLeadRule.vue')
	},
	{
		path: '/queues',
		name: 'LeadDistributionQueues',
		component: () => import(/* webpackChunkName: "queues" */ '../views/LeadDistributionQueues.vue')
	},
	{
		path: '/queue/:queue_id',
		name: 'LeadDistributionQueue',
		component: () => import(/* webpackChunkName: "queue" */ '../views/LeadDistributionQueue.vue')
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
	},
	{
		path: '/logout',
		name: 'Logout',
		component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue')
	},
	{
		path: '/help',
		name: 'Help',
		component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
	},
	{
		path: '/calls',
		name: 'Calls',
		component: () => import(/* webpackChunkName: "calls" */ '../views/Calls.vue')
	},
	{
		path: '/config',
		name: 'Config',
		component: () => import(/* webpackChunkName: "config" */ '../views/Config.vue')
	},
	{
		path: '/motd',
		name: 'MOTD',
		component: () => import(/* webpackChunkName: "motd" */ '../views/MOTD.vue')
	},
	{
		path: '/bug',
		name: 'Bug',
		component: () => import(/* webpackChunkName: "bug" */ '../views/Bug.vue')
	},
	{
		path: '/reports',
		component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue')
	},
	{
		path: '/reports/leads',
		name: "ReportsLeads",
		component: () => import(/* webpackChunkName: "reports_leads" */ '../views/reports/Leads.vue')
	},
	{
		path: '/reports/leads_not_worked',
		component: () => import(/* webpackChunkName: "reports_leads_not_worked" */ '../views/reports/LeadsNotWorked.vue')
	},
	{
		path: '/reports/sales',
		component: () => import(/* webpackChunkName: "reports_sales" */ '../views/reports/Sales.vue')
	},
	{
		path: '/reports/bad_leads',
		component: () => import(/* webpackChunkName: "reports_bad_leads" */ '../views/reports/BadLeads.vue')
	},
	{
		path: '/reports/pipeline',
		component: () => import(/* webpackChunkName: "reports_pipeline" */ '../views/reports/Pipeline.vue')
	},
	{
		path: '/reports/staff',
		component: () => import(/* webpackChunkName: "reports_staff" */ '../views/reports/Staff.vue')
	},
	{
		path: '/reports/time',
		component: () => import(/* webpackChunkName: "reports_time" */ '../views/reports/Time.vue')
	},
	{
		path: '/reports/service',
		component: () => import(/* webpackChunkName: "reports_service" */ '../views/reports/Service.vue')
	},
	{
		path: '/import',
		component: () => import(/* webpackChunkName: "import" */ '../views/imports/Import.vue')
	},
	{
		path: '/import/renewals',
		component: () => import(/* webpackChunkName: "import_renewals" */ '../views/imports/Renewals.vue')
	},
	{
		path: '/import/leads',
		component: () => import(/* webpackChunkName: "import_leads" */ '../views/imports/Leads.vue')
	},
	{
		path: '/service/requests',
		component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue')
	},
	{
		path: '/service/request/:service_id',
		component: () => import(/* webpackChunkName: "services" */ '../views/Service.vue')
	},
	{
		path: '/service',
		component: () => import(/* webpackChunkName: "service" */ '../views/Service.vue')
	},
	{
		path: '/service/forms',
		component: () => import(/* webpackChunkName: "service_forms" */ '../views/ServiceForms.vue')
	},
	{
		path: '/service/:form_id',
		component: () => import(/* webpackChunkName: "service2" */ '../views/Service.vue')
	},
	{
		path: '/service/form/:form_id',
		component: () => import(/* webpackChunkName: "service_form" */ '../views/ServiceForm.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
